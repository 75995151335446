"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildQuestClaimLogToJSON = exports.GuildQuestClaimLogFromJSONTyped = exports.GuildQuestClaimLogFromJSON = void 0;
function GuildQuestClaimLogFromJSON(json) {
    return GuildQuestClaimLogFromJSONTyped(json, false);
}
exports.GuildQuestClaimLogFromJSON = GuildQuestClaimLogFromJSON;
function GuildQuestClaimLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'target': json['target'],
        'date': json['date'],
        'amountReceived': json['amountReceived'],
    };
}
exports.GuildQuestClaimLogFromJSONTyped = GuildQuestClaimLogFromJSONTyped;
function GuildQuestClaimLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'target': value.target,
        'date': value.date,
        'amountReceived': value.amountReceived,
    };
}
exports.GuildQuestClaimLogToJSON = GuildQuestClaimLogToJSON;
