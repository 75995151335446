"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGuildsSortToJSON = exports.GetGuildsSortFromJSONTyped = exports.GetGuildsSortFromJSON = exports.GetGuildsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetGuildsSort;
(function (GetGuildsSort) {
    GetGuildsSort["CreatedAt"] = "createdAt";
    GetGuildsSort["MonthlyPoints"] = "monthlyPoints";
    GetGuildsSort["TotalPoints"] = "totalPoints";
})(GetGuildsSort = exports.GetGuildsSort || (exports.GetGuildsSort = {}));
function GetGuildsSortFromJSON(json) {
    return GetGuildsSortFromJSONTyped(json, false);
}
exports.GetGuildsSortFromJSON = GetGuildsSortFromJSON;
function GetGuildsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetGuildsSortFromJSONTyped = GetGuildsSortFromJSONTyped;
function GetGuildsSortToJSON(value) {
    return value;
}
exports.GetGuildsSortToJSON = GetGuildsSortToJSON;
