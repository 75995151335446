"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildMonthlyPointsToJSON = exports.GuildMonthlyPointsFromJSONTyped = exports.GuildMonthlyPointsFromJSON = void 0;
var _1 = require("./");
function GuildMonthlyPointsFromJSON(json) {
    return GuildMonthlyPointsFromJSONTyped(json, false);
}
exports.GuildMonthlyPointsFromJSON = GuildMonthlyPointsFromJSON;
function GuildMonthlyPointsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'guild': (0, _1.GuildFromJSON)(json['guild']),
        'month': json['month'],
        'year': json['year'],
        'monthlyTotalPoints': json['monthlyTotalPoints'],
        'grandTotalPoints': json['grandTotalPoints'],
    };
}
exports.GuildMonthlyPointsFromJSONTyped = GuildMonthlyPointsFromJSONTyped;
function GuildMonthlyPointsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'guild': (0, _1.GuildToJSON)(value.guild),
        'month': value.month,
        'year': value.year,
        'monthlyTotalPoints': value.monthlyTotalPoints,
        'grandTotalPoints': value.grandTotalPoints,
    };
}
exports.GuildMonthlyPointsToJSON = GuildMonthlyPointsToJSON;
