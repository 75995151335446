"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGuildProgressResponseToJSON = exports.GetGuildProgressResponseFromJSONTyped = exports.GetGuildProgressResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetGuildProgressResponseFromJSON(json) {
    return GetGuildProgressResponseFromJSONTyped(json, false);
}
exports.GetGuildProgressResponseFromJSON = GetGuildProgressResponseFromJSON;
function GetGuildProgressResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'guild': (0, _1.GuildFromJSON)(json['guild']),
        'isUserInGuild': json['isUserInGuild'],
        'hasUserMintedBadge': json['hasUserMintedBadge'],
        'mintBadgeDate': !(0, runtime_1.exists)(json, 'mintBadgeDate') ? undefined : json['mintBadgeDate'],
        'doesUserHaveBadge': json['doesUserHaveBadge'],
        'isGuildQualifierQuestsCompleted': json['isGuildQualifierQuestsCompleted'],
        'members': json['members'],
        'quests': (json['quests'].map(_1.GuildQuestFromJSON)),
    };
}
exports.GetGuildProgressResponseFromJSONTyped = GetGuildProgressResponseFromJSONTyped;
function GetGuildProgressResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'guild': (0, _1.GuildToJSON)(value.guild),
        'isUserInGuild': value.isUserInGuild,
        'hasUserMintedBadge': value.hasUserMintedBadge,
        'mintBadgeDate': value.mintBadgeDate,
        'doesUserHaveBadge': value.doesUserHaveBadge,
        'isGuildQualifierQuestsCompleted': value.isGuildQualifierQuestsCompleted,
        'members': value.members,
        'quests': (value.quests.map(_1.GuildQuestToJSON)),
    };
}
exports.GetGuildProgressResponseToJSON = GetGuildProgressResponseToJSON;
