"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountExperienceTransactionSubjectToJSON = exports.AccountExperienceTransactionSubjectFromJSONTyped = exports.AccountExperienceTransactionSubjectFromJSON = exports.AccountExperienceTransactionSubject = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var AccountExperienceTransactionSubject;
(function (AccountExperienceTransactionSubject) {
    AccountExperienceTransactionSubject["GLEAMREWARD"] = "GLEAM_REWARD";
    AccountExperienceTransactionSubject["CANDYBLAST"] = "CANDY_BLAST";
    AccountExperienceTransactionSubject["PIZZACAFE"] = "PIZZA_CAFE";
    AccountExperienceTransactionSubject["BURGERCAFE"] = "BURGER_CAFE";
    AccountExperienceTransactionSubject["RALLYCHAMPION"] = "RALLY_CHAMPION";
    AccountExperienceTransactionSubject["SPIN2WIN"] = "SPIN2WIN";
    AccountExperienceTransactionSubject["INTHEWEEDS"] = "IN_THE_WEEDS";
    AccountExperienceTransactionSubject["OTHER"] = "OTHER";
    AccountExperienceTransactionSubject["ADMINDEBIT"] = "ADMIN_DEBIT";
    AccountExperienceTransactionSubject["ADMINCREDIT"] = "ADMIN_CREDIT";
    AccountExperienceTransactionSubject["MENUORDERREWARD"] = "MENU_ORDER_REWARD";
    AccountExperienceTransactionSubject["LOADDPAY"] = "LOAD_DPAY";
    AccountExperienceTransactionSubject["INDUSTRYPASSPURCHASEREWARD"] = "INDUSTRY_PASS_PURCHASE_REWARD";
    AccountExperienceTransactionSubject["REFERAFRIENDFIRSTORDERREWARD"] = "REFER_A_FRIEND_FIRST_ORDER_REWARD";
    AccountExperienceTransactionSubject["SIGNUP"] = "SIGN_UP";
    AccountExperienceTransactionSubject["LEVELUP"] = "LEVEL_UP";
    AccountExperienceTransactionSubject["OVERWOLFTIMEREWARD"] = "OVERWOLF_TIME_REWARD";
    AccountExperienceTransactionSubject["OVERWOLFEVENTREWARD"] = "OVERWOLF_EVENT_REWARD";
    AccountExperienceTransactionSubject["OVERWOLFTIMEQUESTREWARD"] = "OVERWOLF_TIME_QUEST_REWARD";
    AccountExperienceTransactionSubject["GUILDEVENTQUESTREWARD"] = "GUILD_EVENT_QUEST_REWARD";
    AccountExperienceTransactionSubject["GUILDTIMEQUESTREWARD"] = "GUILD_TIME_QUEST_REWARD";
    AccountExperienceTransactionSubject["DISCORDDAILYXP"] = "DISCORD_DAILY_XP";
})(AccountExperienceTransactionSubject = exports.AccountExperienceTransactionSubject || (exports.AccountExperienceTransactionSubject = {}));
function AccountExperienceTransactionSubjectFromJSON(json) {
    return AccountExperienceTransactionSubjectFromJSONTyped(json, false);
}
exports.AccountExperienceTransactionSubjectFromJSON = AccountExperienceTransactionSubjectFromJSON;
function AccountExperienceTransactionSubjectFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.AccountExperienceTransactionSubjectFromJSONTyped = AccountExperienceTransactionSubjectFromJSONTyped;
function AccountExperienceTransactionSubjectToJSON(value) {
    return value;
}
exports.AccountExperienceTransactionSubjectToJSON = AccountExperienceTransactionSubjectToJSON;
