"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWGameEventTypeToJSON = exports.OWGameEventTypeFromJSONTyped = exports.OWGameEventTypeFromJSON = exports.OWGameEventType = void 0;
/**
 * The type of game event
 * @export
 * @enum {string}
 */
var OWGameEventType;
(function (OWGameEventType) {
    OWGameEventType["KILL"] = "KILL";
    OWGameEventType["ASSIST"] = "ASSIST";
    OWGameEventType["DEATH"] = "DEATH";
    OWGameEventType["DEFEAT"] = "DEFEAT";
    OWGameEventType["MATCHEND"] = "MATCH_END";
    OWGameEventType["MATCHENDVICTORY"] = "MATCH_END_VICTORY";
    OWGameEventType["MATCHSTART"] = "MATCH_START";
    OWGameEventType["HEADSHOT"] = "HEADSHOT";
    OWGameEventType["PLAYTIME"] = "PLAYTIME";
    OWGameEventType["GOAL"] = "GOAL";
    OWGameEventType["OVERTIME"] = "OVERTIME";
    OWGameEventType["VICTORY"] = "VICTORY";
})(OWGameEventType = exports.OWGameEventType || (exports.OWGameEventType = {}));
function OWGameEventTypeFromJSON(json) {
    return OWGameEventTypeFromJSONTyped(json, false);
}
exports.OWGameEventTypeFromJSON = OWGameEventTypeFromJSON;
function OWGameEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OWGameEventTypeFromJSONTyped = OWGameEventTypeFromJSONTyped;
function OWGameEventTypeToJSON(value) {
    return value;
}
exports.OWGameEventTypeToJSON = OWGameEventTypeToJSON;
