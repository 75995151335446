"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGuildOwnershipResponseToJSON = exports.GetGuildOwnershipResponseFromJSONTyped = exports.GetGuildOwnershipResponseFromJSON = void 0;
var _1 = require("./");
function GetGuildOwnershipResponseFromJSON(json) {
    return GetGuildOwnershipResponseFromJSONTyped(json, false);
}
exports.GetGuildOwnershipResponseFromJSON = GetGuildOwnershipResponseFromJSON;
function GetGuildOwnershipResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'badges': (json['badges'].map(_1.NftOwnershipFromJSON)),
        'qualifiedGuilds': (json['qualifiedGuilds'].map(_1.GuildFromJSON)),
        'otherGuilds': (json['otherGuilds'].map(_1.GuildFromJSON)),
    };
}
exports.GetGuildOwnershipResponseFromJSONTyped = GetGuildOwnershipResponseFromJSONTyped;
function GetGuildOwnershipResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'badges': (value.badges.map(_1.NftOwnershipToJSON)),
        'qualifiedGuilds': (value.qualifiedGuilds.map(_1.GuildToJSON)),
        'otherGuilds': (value.otherGuilds.map(_1.GuildToJSON)),
    };
}
exports.GetGuildOwnershipResponseToJSON = GetGuildOwnershipResponseToJSON;
