"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopLeaderboardGuildToJSON = exports.TopLeaderboardGuildFromJSONTyped = exports.TopLeaderboardGuildFromJSON = void 0;
var runtime_1 = require("../runtime");
function TopLeaderboardGuildFromJSON(json) {
    return TopLeaderboardGuildFromJSONTyped(json, false);
}
exports.TopLeaderboardGuildFromJSON = TopLeaderboardGuildFromJSON;
function TopLeaderboardGuildFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'guildIcon': !(0, runtime_1.exists)(json, 'guildIcon') ? undefined : json['guildIcon'],
        'guildName': json['guildName'],
        'totalPoints': json['totalPoints'],
        'rank': !(0, runtime_1.exists)(json, 'rank') ? undefined : json['rank'],
    };
}
exports.TopLeaderboardGuildFromJSONTyped = TopLeaderboardGuildFromJSONTyped;
function TopLeaderboardGuildToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'guildIcon': value.guildIcon,
        'guildName': value.guildName,
        'totalPoints': value.totalPoints,
        'rank': value.rank,
    };
}
exports.TopLeaderboardGuildToJSON = TopLeaderboardGuildToJSON;
